import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import './PackageDetail.css';

const PackageDetail = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  // Package data (you can modify this directly)
  const packageData = {
    id: 'pinnawala-tour',
    name: 'A Day at Pinnawala Elephant Orphanage',
    description: 'Pinnawala Elephant Orphanage, situated along the banks of the Maha Oya River, is an iconic wildlife sanctuary that spans approximately 25 acres. Established by the Sri Lankan Department of Wildlife Conservation in 1975, the orphanage was initially intended to house five orphaned elephants. Today, it has grown into a vital institution for the care of over 80 elephants, including calves, juveniles, and elderly elephants, many of whom have been rescued from the wild due to various circumstances such as abandonment, injury, or human-elephant conflict.',
    images: [
      '/images/pinnawala/pin1.jpg',
      '/images/pinnawala/pin2.jpg',
      '/images/pinnawala/pin3.jpg',
      '/images/pinnawala/pin4.jpg',
    ],
    itinerary: [
      { day: 1, description: "Morning feeding session at 9:15 AM, followed by a visit to the river for the elephants' daily bath at 10:00 AM. Afternoon feeding session at 2:00 PM, followed by another visit to the river for the elephants' bath. Explore the on-site research center and learn about elephant behavior, breeding programs, and conservation strategies." },
    ],
    included: [
      "Transportation in a comfortable, air-conditioned vehicle",
      "English-speaking chauffeur guide",
      "Refreshments during the tour"
    ],
    reviews: [
      { id: 1, text: 'A heartwarming experience! Watching the elephants being fed and bathed was unforgettable. The guides were very informative.', author: 'Alice Johnson', rating: 5 },
      { id: 2, text: 'An amazing place dedicated to elephant conservation. The educational tours were very insightful. Highly recommend!', author: 'Michael Brown', rating: 4 },
      { id: 3, text: 'A must-visit for animal lovers. The breeding program and the elephant dung paper factory were fascinating.', author: 'Emily Davis', rating: 5 },
      { id: 4, text: 'A wonderful day spent learning about elephants and their care. The river bathing session was a highlight!', author: 'David Wilson', rating: 4 },
    ],
};

  
  
  
  

  

  // Simulated similar packages (you can modify this directly)
  const similarPackages = [
    { id: 'kandyTour', name: 'Kandy City Tour', image: '/images/kandy.jpg' },
    { id: 'sigiriyaTour', name: 'Sigiriya Day Tour', image: '/images/sig.jpg' },
    
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [packageData.images.length]);

  const nextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + packageData.images.length) % packageData.images.length);
  };

  return (
    <motion.div 
      className="package-detail"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="package-hero"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="image-slider">
          <AnimatePresence initial={false}>
            <motion.img
              key={activeImageIndex}
              src={packageData.images[activeImageIndex]}
              alt={`${packageData.name} - Image ${activeImageIndex + 1}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
          <button className="slider-button prev" onClick={prevImage}><ChevronLeft /></button>
          <button className="slider-button next" onClick={nextImage}><ChevronRight /></button>
        </div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {packageData.name}
        </motion.h1>
      </motion.div>
      <motion.div 
        className="package-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <button onClick={() => navigate('/#packages')} className="back-button">
          <ChevronLeft /> Back to Packages
        </button>

        <section className="overview">
          <h2>Overview</h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {showFullDescription 
              ? packageData.description 
              : `${packageData.description.slice(0, 150)}...`}
            <button 
              className="read-more"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Read Less' : 'Read More'}
            </button>
          </motion.p>
        </section>

        <section className="itinerary">
          <h2>Itinerary</h2>
          <ul>
            {packageData.itinerary.map((item, index) => (
              <motion.li 
                key={item.day}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <strong>Day {item.day}:</strong> {item.description}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="included">
          <h2>Included</h2>
          <ul>
            {packageData.included.map((item, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {item}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="reviews">
          <h2>Reviews</h2>
          {packageData.reviews.map((review, index) => (
            <motion.div 
              key={review.id} 
              className="review"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <p>"{review.text}"</p>
              <div className="review-meta">
                <span>{review.author}</span>
                <div className="stars">
                  {[...Array(review.rating)].map((_, i) => (
                    <Star key={i} size={16} fill="#ffd700" stroke="#ffd700" />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </section>

        <section className="similar-packages">
          <h2>Similar Packages</h2>
          <motion.div 
            className="similar-packages-grid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            {similarPackages.map((pkg, index) => (
              <motion.div
                key={pkg.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
              >
                <div onClick={() => navigate(`/packages/${pkg.id}`)} className="similar-package">
                  <img src={pkg.image} alt={pkg.name} />
                  <h3>{pkg.name}</h3>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </section>

        
      </motion.div>
    </motion.div>
  );
};

export default PackageDetail;