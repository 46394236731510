import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import './PackageDetail.css';

const PackageDetail = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  // Package data (you can modify this directly)
  const packageData = {
    id: 'kandy-tour',
    name: 'Discover the Heart of Kandy',
    description: 'Embark on an unforgettable journey through Kandy, Sri Lanka\'s cultural capital. This captivating city offers a blend of ancient history, natural beauty, and vibrant culture. From the sacred Temple of the Tooth Relic to the serene Kandy Lake, every corner of this city tells a story. Wander through the lush Royal Botanical Gardens, climb to the top of Bahirawakanda Temple for breathtaking views, and immerse yourself in the vibrant energy of traditional Kandyan dance. Discover the beauty of Sri Lankan gems, witness skilled artisans at work, and explore fragrant spice gardens. Savor the local cuisine, rich with aromatic curries and refreshing coconut milk-based desserts. Whether you seek spiritual enlightenment, natural beauty, or cultural immersion, Kandy promises an unforgettable experience.',
    images: [
      '/images/kandy/kan4.jpg',
      '/images/kandy/kan6.jpg',
      '/images/kandy/kan9.jpg',
      '/images/kandy/kan10.jpg',
    ],
    itinerary: [
      { day: 1, description: 'Temple of the Tooth Relic (Sri Dalada Maligawa): Witness the sacred relic of the Buddha\'s tooth, a symbol of Buddhism in Sri Lanka.' },
      { day: 2, description: 'Royal Botanical Gardens: Wander through this lush oasis, home to a diverse collection of plants and flowers.' },
      { day: 3, description: 'Kandy Lake: Enjoy a peaceful stroll around the serene lake, surrounded by lush greenery.' },
      { day: 4, description: 'Bahirawakanda Temple: Climb to the top of this hilltop temple for breathtaking panoramic views of the city.' },
      { day: 5, description: 'Kandy Cultural Dance Show: Experience the vibrant energy of traditional Kandyan dance, drumming, and fire-walking performances.' },
      { day: 6, description: 'Gem and Gemological Museum: Discover the beauty of Sri Lankan gems and learn about their history and mining processes.' },
      { day: 7, description: 'Woodcraft Workshop: Witness skilled artisans crafting intricate wooden souvenirs using traditional techniques.' },
      { day: 8, description: 'Spice Garden: Explore a fragrant spice garden and learn about the cultivation and uses of various spices.' },
      { day: 9, description: 'Local Cuisine: Savor delicious Sri Lankan cuisine, including aromatic curries, flavorful rice and lentil dishes, and refreshing coconut milk-based desserts.' },
    ],
    included: ['Accommodation', 'Meals', 'Guided tours', 'Transportation'],
    reviews: [
      { id: 1, text: 'An amazing experience! The cultural richness and natural beauty of Kandy are unparalleled. The guides were very knowledgeable and made the trip even more enjoyable.', author: 'Alice Johnson', rating: 5 },
      { id: 2, text: 'A perfect blend of history, culture, and nature. The itinerary was well-planned, and the accommodations were comfortable. Highly recommend this tour to anyone visiting Sri Lanka.', author: 'Michael Brown', rating: 4 },
      { id: 3, text: 'Kandy is a gem! The cultural dance show was a highlight, and the visit to the Temple of the Tooth Relic was a spiritual experience. The food was delicious too!', author: 'Emily Davis', rating: 5 },
      { id: 4, text: 'A wonderful journey through Kandy. The botanical gardens were stunning, and the views from Bahirawakanda Temple were breathtaking. A must-do tour!', author: 'David Wilson', rating: 4 },
    ],
  };
  
  
  
  

  

  // Simulated similar packages (you can modify this directly)
  const similarPackages = [
    { id: 'pinnawalaTour', name: 'Pinnawala Day Tour', image: '/images/pinnawala.jpg' },
    { id: 'sigiriyaTour', name: 'Sigiriya Day Tour', image: '/images/sig.jpg' },
   
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [packageData.images.length]);

  const nextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + packageData.images.length) % packageData.images.length);
  };

  return (
    <motion.div 
      className="package-detail"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="package-hero"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="image-slider">
          <AnimatePresence initial={false}>
            <motion.img
              key={activeImageIndex}
              src={packageData.images[activeImageIndex]}
              alt={`${packageData.name} - Image ${activeImageIndex + 1}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
          <button className="slider-button prev" onClick={prevImage}><ChevronLeft /></button>
          <button className="slider-button next" onClick={nextImage}><ChevronRight /></button>
        </div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {packageData.name}
        </motion.h1>
      </motion.div>
      <motion.div 
        className="package-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <button onClick={() => navigate('/#packages')} className="back-button">
          <ChevronLeft /> Back to Packages
        </button>

        <section className="overview">
          <h2>Overview</h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {showFullDescription 
              ? packageData.description 
              : `${packageData.description.slice(0, 150)}...`}
            <button 
              className="read-more"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Read Less' : 'Read More'}
            </button>
          </motion.p>
        </section>

        <section className="itinerary">
          <h2>Itinerary</h2>
          <ul>
            {packageData.itinerary.map((item, index) => (
              <motion.li 
                key={item.day}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <strong>Day {item.day}:</strong> {item.description}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="included">
          <h2>Included</h2>
          <ul>
            {packageData.included.map((item, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {item}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="reviews">
          <h2>Reviews</h2>
          {packageData.reviews.map((review, index) => (
            <motion.div 
              key={review.id} 
              className="review"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <p>"{review.text}"</p>
              <div className="review-meta">
                <span>{review.author}</span>
                <div className="stars">
                  {[...Array(review.rating)].map((_, i) => (
                    <Star key={i} size={16} fill="#ffd700" stroke="#ffd700" />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </section>

        <section className="similar-packages">
          <h2>Similar Packages</h2>
          <motion.div 
            className="similar-packages-grid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            {similarPackages.map((pkg, index) => (
              <motion.div
                key={pkg.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
              >
                <div onClick={() => navigate(`/packages/${pkg.id}`)} className="similar-package">
                  <img src={pkg.image} alt={pkg.name} />
                  <h3>{pkg.name}</h3>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </section>

        
      </motion.div>
    </motion.div>
  );
};

export default PackageDetail;