import React from 'react';
import { motion } from 'framer-motion';
import { MapPin } from 'lucide-react';
import '../styles/PopularDestinations.css';

const destinations = [
  {
    name: "Sigiriya Rock Fortress",
    description: "Explore the ancient rock fortress, a UNESCO World Heritage site.",
    image: "/images/sigiriya.jpg"
  },
  {
    name: "Ella",
    description: "Discover the scenic beauty of Ella, with its stunning views and trails.",
    image: "/images/ella.jpg"
  },
  {
    name: "Yala National Park",
    description: "Experience the wildlife at Yala, home to leopards, elephants, and more.",
    image: "/images/yala.jpg"
  },
  {
    name: "Galle Fort",
    description: "Walk through history at the Galle Fort, a historic colonial fort.",
    image: "/images/galle.jpg"
  }
];

const PopularDestinations = () => {
  return (
    <div className="popular-destinations" id="destinations">
      <div className="container">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Explore Our Popular Destinations
        </motion.h2>
        <div className="destinations-grid">
          {destinations.map((dest, index) => (
            <motion.div 
              key={index}
              className="destination-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="image-container">
                <img src={dest.image} alt={dest.name} />
                <div className="overlay">
                  <MapPin className="map-icon" />
                </div>
              </div>
              <h3>{dest.name}</h3>
              <p>{dest.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopularDestinations;