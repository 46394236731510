import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaInstagram, FaEnvelope, FaMapMarkerAlt, FaPhone, FaWhatsapp, FaArrowUp } from 'react-icons/fa';
import '../styles/Footer.css';

function Footer() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <motion.footer 
      className="footer"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="contact-info" variants={itemVariants}>
        <h3>Contact Us</h3>
        <p><FaMapMarkerAlt /> No 35/2A, Mawana, Mawanella, Sri Lanka, 71500</p>
        <p><FaEnvelope /> info@ceylonheaventours.com</p>
        <p><FaPhone /> +94 76 066 0003</p>
      </motion.div>
      <motion.div className="social-media" variants={itemVariants}>
        <h3>Follow Us</h3>
        <div className="social-icons">
          <motion.a href="https://www.facebook.com/profile.php?id=61561318045640&mibextid=LQQJ4d" whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}><FaFacebookF /></motion.a>
          <motion.a href="https://www.instagram.com/ceylonheaventours?igsh=Y2k0bW82czQwcGp3" whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}><FaInstagram /></motion.a>
        </div>
      </motion.div>
      <motion.div className="whatsapp-container" variants={itemVariants}>
        <motion.a 
          href="https://wa.me/94760660003"
          className="whatsapp"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaWhatsapp />
        </motion.a>
        <motion.button 
          className="scroll-to-top"
          onClick={scrollToTop}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaArrowUp />
        </motion.button>
      </motion.div>
    </motion.footer>
  );
}

export default Footer;
