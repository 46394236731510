import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import './PackageDetail.css';

const PackageDetail = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  // Package data (you can modify this directly)
  const packageData = {
    id: 'tradition',
    name: 'Discover the Heart of Sri Lanka in 8 Days',
    description: 'This 8-day tour offers a comprehensive exploration of Sri Lanka\'s diverse attractions, from ancient temples to wildlife encounters and breathtaking landscapes.',
    images: [
      '/images/tradition/1.jpg',
      '/images/tradition/2.jpg',
      '/images/tradition/3.jpg',
      '/images/tradition/4.jpg',
      '/images/tradition/5.jpg',
      '/images/tradition/6.jpg',
      '/images/tradition/7.jpg',
      '/images/tradition/8.jpg',
      '/images/tradition/9.jpg',
      '/images/tradition/10.jpg',
      '/images/tradition/11.jpg',
      '/images/tradition/12.jpg',
      '/images/tradition/13.jpg',
      '/images/tradition/14.jpg',
      '/images/tradition/15.jpg',
      '/images/tradition/16.jpg',
      '/images/tradition/17.jpg',
      

      
      
      
    ],
    
    itinerary: [
      { day: 1, description: "Arrive in Kandy and visit Pinnawala Elephant Orphanage." },
      { day: 2, description: "Explore Kandy, including the Temple of the Tooth Relic, Bahirawakanda Temple, and Kandy Viewpoint." },
      { day: 3, description: "Journey to Sigiriya Rock Fortress, Matale Hindu Temple, and a spice garden." },
      { day: 4, description: "Explore the hill country of Nuwara Eliya, including Lake Gregory, Nuwara Eliya Post Office, St. Clair's Falls, and Devon Falls." },
      { day: 5, description: "Embark on a train journey from Nuwara Eliya to Ella, exploring the scenic highlands." },
      { day: 6, description: "Explore Ella, including Little Adam's Peak, Nine Arch Bridge, and Ravana Falls." },
      { day: 7, description: "Embark on a wildlife safari in Yala National Park." },
      { day: 8, description: "Explore Mirissa, including Coconut Tree Hill, and enjoy a whale-watching tour." }
    ],
    included: [
      "Driver/Guide",
      "Driver Accommodation",
      "Breakfast and Dinner",
      "Hotel Pickup and Drop-Off",
      "Private Vehicle Transportation",
      "Bottled Water",
      "Highway Toll Charges",
      "Free Wi-Fi",
      "Local Insurance",
      "Passenger Insurance",
      "Air-Conditioned Vehicle",
      "Surgical Masks",
      "Sanitizers"
    ],
    reviews: [
      { id: 1, text: "An incredible journey through Sri Lanka's history and culture. Highly recommended!", author: "Emily Clark", rating: 5 },
      { id: 2, text: "A well-organized tour with knowledgeable guides. The Sigiriya Rock Fortress was a highlight!", author: "David Johnson", rating: 4 },
      { id: 3, text: "The tour was fantastic! The guide was very informative and the sites were breathtaking.", author: "Sarah Williams", rating: 5 },
      { id: 4, text: "A memorable experience. The cultural performances were amazing.", author: "James Brown", rating: 4 },
      { id: 5, text: "Loved every moment of this tour. The itinerary was well-planned.", author: "Linda Taylor", rating: 5 },
      { id: 6, text: "A great way to explore Sri Lanka's heritage. Highly recommend this tour.", author: "Michael Smith", rating: 4 }
    ],
};

  
  
  
  

  

  // Simulated similar packages (you can modify this directly)
  const similarPackages = [
    { id: 'classicI', name: 'Beach Paradise', image: '/images/beach.jpg' },
    { id: 'nature', name: 'Natural Wonder', image: '/images/forest.jpg' },
   
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [packageData.images.length]);

  const nextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + packageData.images.length) % packageData.images.length);
  };

  return (
    <motion.div 
      className="package-detail"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="package-hero"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="image-slider">
          <AnimatePresence initial={false}>
            <motion.img
              key={activeImageIndex}
              src={packageData.images[activeImageIndex]}
              alt={`${packageData.name} - Image ${activeImageIndex + 1}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
          <button className="slider-button prev" onClick={prevImage}><ChevronLeft /></button>
          <button className="slider-button next" onClick={nextImage}><ChevronRight /></button>
        </div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {packageData.name}
        </motion.h1>
      </motion.div>
      <motion.div 
        className="package-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <button onClick={() => navigate('/#packages')} className="back-button">
          <ChevronLeft /> Back to Packages
        </button>

        <section className="overview">
          <h2>Overview</h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {showFullDescription 
              ? packageData.description 
              : `${packageData.description.slice(0, 150)}...`}
            <button 
              className="read-more"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Read Less' : 'Read More'}
            </button>
          </motion.p>
        </section>

        <section className="itinerary">
          <h2>Itinerary</h2>
          <ul>
            {packageData.itinerary.map((item, index) => (
              <motion.li 
                key={item.day}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <strong>Day {item.day}:</strong> {item.description}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="included">
          <h2>Included</h2>
          <ul>
            {packageData.included.map((item, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {item}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="reviews">
          <h2>Reviews</h2>
          {packageData.reviews.map((review, index) => (
            <motion.div 
              key={review.id} 
              className="review"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <p>"{review.text}"</p>
              <div className="review-meta">
                <span>{review.author}</span>
                <div className="stars">
                  {[...Array(review.rating)].map((_, i) => (
                    <Star key={i} size={16} fill="#ffd700" stroke="#ffd700" />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </section>

        <section className="similar-packages">
          <h2>Similar Packages</h2>
          <motion.div 
            className="similar-packages-grid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            {similarPackages.map((pkg, index) => (
              <motion.div
                key={pkg.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
              >
                <div onClick={() => navigate(`/packages/${pkg.id}`)} className="similar-package">
                  <img src={pkg.image} alt={pkg.name} />
                  <h3>{pkg.name}</h3>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </section>

       
      </motion.div>
    </motion.div>
  );
};

export default PackageDetail;