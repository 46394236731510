// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Packages from './components/Packages';
import PopularDestinations from './components/PopularDestinations';
import Gallery from './components/Gallery';
import Footer from './components/Footer';

// Import Package Detail Components
import KandyTour from './components/packageDetails/kandyTour';
import PinnawalaTour from './components/packageDetails/pinnawalaTour';
import SigiriyaTour from './components/packageDetails/sigiriyaTour';
import ExplorerDelight from './components/packageDetails/explorerDelight';
import CulturalOdessey from './components/packageDetails/culturalOdessey';
import MiniDiscovery from './components/packageDetails/miniDiscovery';
import BestSri from './components/packageDetails/bestSri';
import HeartSri from './components/packageDetails/heartSri';
import Classic from './components/packageDetails/classic';
import ClassicI from './components/packageDetails/classicI';
import Tradition from './components/packageDetails/tradition';
import Nature from './components/packageDetails/nature';


function App() {
  return (
    <div className="App">
       <Routes>
        <Route path="/" element={
          <>
            <Header />
            <div id="hero">
            <Hero />
            </div>
            <div id="about">
            <About />
            </div>
            <div id="packages">
              <Packages />
            </div>
            <div id="populardestination">
            <PopularDestinations />
            </div>
            <div id="gallery">
              <Gallery />
            </div>
          </>
        } />
        <Route path="/packages/kandyTour" element={<KandyTour />} />
        <Route path="/packages/pinnawalaTour" element={<PinnawalaTour />} />
        <Route path="/packages/sigiriyaTour" element={<SigiriyaTour />} />
        <Route path="/packages/explorerDelight" element={<ExplorerDelight />} />
        <Route path="/packages/culturalOdessey" element={<CulturalOdessey />} />
        <Route path="/packages/miniDiscovery" element={<MiniDiscovery />} />
        <Route path="/packages/bestSri" element={<BestSri />} />
        <Route path="/packages/heartSri" element={<HeartSri />} />
        <Route path="/packages/classic" element={<Classic />} />
        <Route path="/packages/classicI" element={<ClassicI />} />
        <Route path="/packages/tradition" element={<Tradition />} />
        <Route path="/packages/nature" element={<Nature />} />
     
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
