import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Gallery.css'; // Adjust the path to your actual CSS file

function Gallery() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20, rotate: 0, scale: 1 },
    visible: {
      opacity: 1,
      y: 0,
      rotate: Math.random() * 10 - 5, // Random rotation between -5deg and 5deg
      scale: 0.95 + Math.random() * 0.1, // Random scale between 0.95 and 1.05
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  // Replace with your actual image file names and paths
  const imagePaths = [
    'images/gal1.jpg',
    'images/gal2.jpg',
    'images/gal3.jpg',
    'images/gal4.jpg',
    'images/gal5.jpg',
    'images/gal6.jpg',
  ];

  return (
    <motion.div
      className="gallery"
      id="gallery"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.h2 variants={itemVariants}>Gallery</motion.h2>
      <motion.div className="gallery-grid" variants={containerVariants}>
        {imagePaths.map((imagePath, index) => (
          <motion.div
            key={index}
            className="gallery-item"
            variants={itemVariants}
            whileHover={{
              scale: 1.05,
              boxShadow:
                '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            }}
          >
            <img src={imagePath} alt={`Gallery ${index + 1}`} />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}

export default Gallery;
