import React from 'react';
import {  FaInstagram, FaFacebookF, FaImage, FaSuitcase } from 'react-icons/fa';
import '../styles/Hero.css';

const Hero = () => {
  return (
    <div className="hero-section">
      <video className="hero-video" autoPlay loop muted>
        <source src="/videos/hero-bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1>Welcome to Sri Lanka</h1>
        <p>Explore the beauty and culture of Sri Lanka with our exclusive packages.</p>
        <div className="button-container">
          <button className="cta-button" aria-label="Discover More">
            Discover More
          </button>
          <div className="discover-buttons">
            <a href="#gallery" className="discover-button" aria-label="Gallery">
              <FaImage />
            </a>
            <a href="#packages" className="discover-button" aria-label="Packages">
              <FaSuitcase />
            </a>
          </div>
        </div>
        <div className="button-container">
          <button className="secondary-button" aria-label="Contact Us">
            Contact Us
          </button>
          <div className="social-buttons">
            {/* <a href="https://wa.me/your-number" className="social-button whatsapp" aria-label="WhatsApp">
              <FaWhatsapp />
            </a> */}
            <a href="https://www.instagram.com/ceylonheaventours?igsh=Y2k0bW82czQwcGp3" className="social-button instagram" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61561318045640&mibextid=LQQJ4d" className="social-button facebook" aria-label="Facebook">
              <FaFacebookF />
            </a>
          </div>
        </div>
        <div className="scroll-indicator">▼</div>
      </div>
    </div>
  );
};

export default Hero;
