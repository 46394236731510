import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, Compass, Umbrella, Landmark, Star, Users, Clock, Leaf } from 'lucide-react';
import '../styles/Packages.css';

const Packages = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  const packagesData = [
    {
      id: 'Day',
      title: 'Day Tours',
      icon: Compass,
      packages: [
        {
          id: 'kandyTour',
          name: 'Kandy City Tour',
          description: 'Immerse yourself in Kandy\'s rich heritage with a captivating city tour. Explore ancient temples, lush gardens, and vibrant cultural experiences. Witness the sacred tooth relic, admire stunning architecture, and indulge in local cuisine.',
          image: '/images/kandy.jpg',
          rating: 4.8,
          duration: '1 days',
          groupSize: '1+',
        },
        {
          id: 'pinnawalaTour',
          name: 'Pinnawala Day Tour',
          description: 'Experience the joy of interacting with elephants at Pinnawala Elephant Orphanage. Witness their daily routines, learn about their care, and contribute to their conservation efforts.',
          image: '/images/pinnawala.jpg',
          rating: 4.9,
          duration: '1 day',
          groupSize: '1+',
          
        },
        {
          id: 'sigiriyaTour',
          name: 'Sigiriya Day Tour',
          description: 'Discover the ancient wonders of Sigiriya on a full-day tour from Kandy. Explore majestic temples, lush spice gardens, and the iconic Sigiriya Rock Fortress, offering a blend of history, culture, and natural beauty.',
          image: '/images/sig.jpg',
          rating: 4.7,
          duration: '1 day',
          groupSize: '1+',
          
        },
      ],
    },
    {
      id: 'beach',
      title: 'Budget Tours',
      icon: Umbrella,
      packages: [
        {
          id: 'explorerDelight',
          name: 'Explorer\'s Delight',
          description: 'Embark on a 4-day cultural journey through Sri Lanka\'s ancient cities. Explore the sacred Temple of the Tooth in Kandy, climb the iconic Sigiriya Rock Fortress, and discover the rich history of Anuradhapura. This budget-friendly tour offers a perfect blend of spiritual sites and natural wonders.',
          image: '/images/anu.jpg',
          rating: 4.9,
          duration: '4 days',
          groupSize: '2+',
          
        },
        {
          id: 'culturalOdessey',
          name: 'Cultural Odyssey',
          description: 'Experience the best of Sri Lanka\'s cultural triangle in 5 days. From the sacred Temple of the Tooth in Kandy to the ancient Sigiriya Rock Fortress and the tea plantations of Nuwara Eliya, this budget-friendly tour offers a perfect blend of history, culture, and natural beauty.',
          image: '/images/dumb.jpg',
          rating: 4.8,
          duration: '5 days',
          groupSize: '1+',
        
        },
        {
          id: 'miniDiscovery',
          name: 'Mini Discovery',
          description: 'Embark on a 3-day adventure through Sri Lanka\'s southern coast. Explore the historic Galle Fort, witness wildlife safaris in Yala National Park, and relax on the pristine beaches of Mirissa. This itinerary offers a perfect blend of history, adventure, and relaxation.',
          image: '/images/ga.jpg',
          rating: 4.7,
          duration: '3 days',
          groupSize: '1+',
          
        },
      ],
    },
    {
      id: 'cultural',
      title: 'Main Tours',
      icon: Landmark,
      packages: [
        {
          id: 'bestSri',
          name: 'Best of Sri Lanka',
          description: 'Embark on a 10-day adventure through Sri Lanka\'s cultural and natural wonders. Explore ancient cities, witness wildlife safaris, hike to breathtaking viewpoints, and relax on pristine beaches. This comprehensive tour offers a perfect blend of history, adventure, and relaxation.',
          image: '/images/main1.jpg',
          rating: 4.9,
          duration: '10 days',
          groupSize: '1+',
          
        },
        {
          id: 'heartSri',
          name: 'Heart of Sri Lanka',
          description: 'Embark on a 14-day adventure through Sri Lanka\'s diverse landscapes and rich cultural heritage. Explore ancient cities, witness wildlife safaris, relax on pristine beaches, and discover hidden gems. This comprehensive tour offers a truly unforgettable experience.',
          image: 'images/ancient.jpg',
          rating: 4.8,
          duration: '14 days',
          groupSize: '1+',
         
        },
        {
          id: 'classic',
          name: 'A Cultural Odessey',
          description: 'This 11-night, 12-day tour offers a comprehensive exploration of Sri Lanka\'s cultural and natural treasures. From ancient temples to wildlife safaris and breathtaking landscapes, this itinerary provides a diverse and unforgettable experience.',
          image: '/images/heritage.jpg',
          rating: 4.7,
          duration: '11 days',
          groupSize: '1+',
        
        },
        {
          id: 'classicI',
          name: 'Beach Paradise',
          description: 'Embark on a 10-day beach holiday in Sri Lanka. Explore the cultural city of Kandy, witness elephants at Pinnawala, and relax on the pristine beaches of Tangalle and Weligama. Enjoy a perfect blend of adventure and relaxation.',
          image: '/images/beach.jpg',
          rating: 4.8,
          duration: '10 days',
          groupSize: '1+',
       
        },
        {
          id: 'tradition',
          name: 'Traditional Blast',
          description: 'Embark on an 8-day journey through Sri Lanka\'s cultural and natural wonders. Explore ancient temples, witness elephants at Pinnawala, hike to Sigiriya Rock Fortress, and relax in the hill country of Kandy and Ella.',
          image: '/images/temple.jpg',
          rating: 4.9,
          duration: '8 days',
          groupSize: '1+',

        },
        {
          id: 'nature',
          name: 'Natural Wonder',
          description: 'Embark on a 7-day adventure through Sri Lanka\'s cultural and natural wonders. Explore ancient temples, witness elephants at Pinnawala, hike to Sigiriya Rock Fortress, and relax on the pristine beaches of Mirissa and Bentota.',
          image: '/images/forest.jpg',
          rating: 4.9,
          duration: '7 days',
          groupSize: '1+',

        },
      ],
    },
    
  ];

  return (
    <div className="packages-section" id="packages">
      <div className="container">
        <motion.h2 
          className="section-title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Discover Our Eco-Friendly Packages
        </motion.h2>
        <div className="packages-container">
          {packagesData.map((category) => (
            <motion.div 
              key={category.id} 
              className="package-category"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div
                className="category-header"
                onClick={() => toggleCategory(category.id)}
              >
                <h3>
                  <category.icon className="category-icon" />
                  {category.title}
                </h3>
                {activeCategory === category.id ? <ChevronUp /> : <ChevronDown />}
              </div>
              <AnimatePresence>
                {activeCategory === category.id && (
                  <motion.div 
                    className="category-content"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {category.packages.map((pkg) => (
                      <motion.div 
                        key={pkg.id} 
                        className="package-card"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className="image-container">
                          <img src={pkg.image} alt={pkg.name} />
                          <div className="overlay" />
                        </div>
                        <div className="package-details">
                          <h4>{pkg.name}</h4>
                          <p>{pkg.description}</p>
                          <div className="package-meta">
                            <span><Star size={16} /> {pkg.rating}</span>
                            <span><Clock size={16} /> {pkg.duration}</span>
                            <span><Users size={16} /> {pkg.groupSize}</span>
                          </div>
                          <div className="package-footer">
                            <span className="eco-cert"><Leaf size={16} /> {pkg.ecoCert}</span>
                            <Link to={`/packages/${pkg.id}`} className="learn-more-button">
                              Learn More
                            </Link>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Packages;