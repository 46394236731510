import React, { useState } from 'react';
import { TreePalm, Mountain, Waves, Sun } from 'lucide-react';
import '../styles/About.css';

const About = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const naturalWonders = [
    { 
      icon: TreePalm, 
      title: 'Lush Forests', 
      description: 'Explore dense jungles filled with towering trees and vibrant flora, and spot exotic wildlife in our expansive national parks.' 
    },
    { 
      icon: Mountain, 
      title: 'Misty Mountains', 
      description: 'Hike through picturesque tea plantations, breathe in the fresh mountain air, and climb Adam\'s Peak for breathtaking panoramic views.' 
    },
    { 
      icon: Waves, 
      title: 'Pristine Beaches', 
      description: 'Relax on golden sands, soak up the sun, and dive into crystal-clear waters teeming with colorful marine life and coral reefs.' 
    },
    { 
      icon: Sun, 
      title: 'Ancient Wonders', 
      description: 'Discover the rich history and cultural heritage at UNESCO World Heritage sites like Sigiriya and the Sacred City of Anuradhapura.' 
    },
  ];
  
  return (
    <div className="about-section" id="about">
      <div className="container">
        <h2 className="section-title animate-fadeInUp">Discover the Natural Wonders of Sri Lanka</h2>
        
        <div className="content-wrapper">
        <div className="text-content animate-fadeInUp">
  <p>Welcome to Sri Lanka's premier eco-tourism experience. Our passion is to showcase the incredible biodiversity and natural beauty of this island paradise.</p>
  <p>From the misty mountains of the central highlands to the sun-soaked beaches of the coast, we offer immersive tours that connect you with nature's wonders.</p>
  <p>Our expert guides are committed to sustainable practices, ensuring that your adventure leaves a positive impact on local communities and ecosystems.</p>
  <p>Explore lush rainforests teeming with exotic wildlife, wander through ancient temples hidden in the jungle, and relax in serene tea plantations that stretch as far as the eye can see. Whether you're an avid bird watcher, a hiking enthusiast, or simply looking to unwind in nature's embrace, our tours cater to all interests and activity levels.</p>
  <p>We believe in responsible tourism that not only provides unforgettable experiences but also fosters a deep respect for the environment. Join us in preserving the natural heritage of Sri Lanka while creating memories that will last a lifetime.</p>
  <p>Embark on a journey with us and discover the true essence of eco-tourism, where every step you take is a step towards a more sustainable future.</p>
</div>

          <div className="image-container animate-fadeInUp">
            <img src="/images/about.jpg" alt="Sri Lankan landscape" />
          </div>
        </div>
        
        <h3 className="wonders-title animate-fadeInUp">Experience Sri Lanka's Natural Diversity</h3>
        
        <div className="wonders-grid">
          {naturalWonders.map((wonder, index) => (
            <div 
              key={index} 
              className={`wonder-item ${hoveredIndex === null ? 'animate-popIn' : ''}`} 
              style={{animationDelay: `${0.1 * index}s`}}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <wonder.icon 
                className="wonder-icon" 
                size={36} 
                style={{
                  transform: hoveredIndex === index ? 'scale(1.2)' : 'scale(1)',
                  transition: 'transform 0.3s ease'
                }}
              />
              <div className="wonder-content">
                <h4>{wonder.title}</h4>
                <p>{wonder.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;