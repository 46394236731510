import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import './PackageDetail.css';

const PackageDetail = () => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();

  // Package data (you can modify this directly)
  const packageData = {
    id: 'pinnawala-tour',
    name: 'Sigiriya Day Tour from Kandy',
    description: 'Explore the cultural and historical wonders of Sri Lanka with a day tour from Kandy to Sigiriya. This tour includes visits to the Matale Hindu Temple, Spice Garden, Dambulla Rock Cave Temple, and the iconic Sigiriya Rock Fortress. Experience the local village life in Sigiriya and enjoy a traditional Sri Lankan lunch.',
    images: [
      '/images/sigiriya/sig1.jpg',
      '/images/sigiriya/sig2.jpg',
      '/images/sigiriya/sig3.jpg',
      
    ],
  
    itinerary: [
      { day: 1, description: "Depart from your hotel in Kandy and enjoy a scenic drive through lush landscapes and picturesque countryside." },
      { day: 1, description: "Visit the Matale Hindu Temple, renowned for its vibrant Dravidian architecture and intricate carvings." },
      { day: 1, description: "Continue to a nearby Spice Garden to discover the variety of spices Sri Lanka is famous for. Enjoy a guided tour and complimentary herbal tea." },
      { day: 1, description: "Visit the Dambulla Rock Cave Temple, a UNESCO World Heritage Site, and marvel at the ancient art and serene atmosphere." },
      { day: 1, description: "Enjoy a traditional Sri Lankan lunch at a local restaurant." },
      { day: 1, description: "Proceed to the Sigiriya Rock Fortress, known as the 'Lion Rock,' and enjoy panoramic views from the summit." },
      { day: 1, description: "Experience rural life in Sigiriya with a village tour, including a bullock cart ride and a boat ride on a serene lake." },
      { day: 1, description: "Begin your journey back to Kandy, reflecting on the day's adventures." }
    ],
    included: [
      "Transportation in a comfortable, air-conditioned vehicle",
      "English-speaking chauffeur guide",
      "Refreshments during the tour"
    ],
    reviews: [
      { id: 1, text: "An amazing day filled with cultural and historical insights. The Sigiriya Rock Fortress was the highlight!", author: "Alice Brown", rating: 5 },
      { id: 2, text: "A well-organized tour with knowledgeable guides. Highly recommend!", author: "Michael Lee", rating: 4 }
    ],
};

  
  
  
  

  

  // Simulated similar packages (you can modify this directly)
  const similarPackages = [
    { id: 'kandyTour', name: 'Kandy City Tour', image: '/images/kandy.jpg' },
    { id: 'pinnawalaTour', name: 'Pinnawala Day Tour', image: '/images/pinnawala.jpg' },
   
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [packageData.images.length]);

  const nextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % packageData.images.length);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + packageData.images.length) % packageData.images.length);
  };

  return (
    <motion.div 
      className="package-detail"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="package-hero"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="image-slider">
          <AnimatePresence initial={false}>
            <motion.img
              key={activeImageIndex}
              src={packageData.images[activeImageIndex]}
              alt={`${packageData.name} - Image ${activeImageIndex + 1}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
          <button className="slider-button prev" onClick={prevImage}><ChevronLeft /></button>
          <button className="slider-button next" onClick={nextImage}><ChevronRight /></button>
        </div>
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {packageData.name}
        </motion.h1>
      </motion.div>
      <motion.div 
        className="package-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <button onClick={() => navigate('/#packages')} className="back-button">
          <ChevronLeft /> Back to Packages
        </button>

        <section className="overview">
          <h2>Overview</h2>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {showFullDescription 
              ? packageData.description 
              : `${packageData.description.slice(0, 150)}...`}
            <button 
              className="read-more"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Read Less' : 'Read More'}
            </button>
          </motion.p>
        </section>

        <section className="itinerary">
          <h2>Itinerary</h2>
          <ul>
            {packageData.itinerary.map((item, index) => (
              <motion.li 
                key={item.day}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <strong>Day {item.day}:</strong> {item.description}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="included">
          <h2>Included</h2>
          <ul>
            {packageData.included.map((item, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {item}
              </motion.li>
            ))}
          </ul>
        </section>

        <section className="reviews">
          <h2>Reviews</h2>
          {packageData.reviews.map((review, index) => (
            <motion.div 
              key={review.id} 
              className="review"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <p>"{review.text}"</p>
              <div className="review-meta">
                <span>{review.author}</span>
                <div className="stars">
                  {[...Array(review.rating)].map((_, i) => (
                    <Star key={i} size={16} fill="#ffd700" stroke="#ffd700" />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </section>

        <section className="similar-packages">
          <h2>Similar Packages</h2>
          <motion.div 
            className="similar-packages-grid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            {similarPackages.map((pkg, index) => (
              <motion.div
                key={pkg.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 + index * 0.1 }}
              >
                <div onClick={() => navigate(`/packages/${pkg.id}`)} className="similar-package">
                  <img src={pkg.image} alt={pkg.name} />
                  <h3>{pkg.name}</h3>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </section>

        
      </motion.div>
    </motion.div>
  );
};

export default PackageDetail;